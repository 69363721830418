<template>
    <v-container fluid>
        <v-form>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-row class="flex-nowrap">
                                    <v-col cols="1" style="max-width: 80px">
                                        <v-btn :to="{name: 'attraction'}" class="back-btn" link large>
                                            <v-icon>mdi-less-than</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="11">
                                        <v-subheader class="headline">{{ heading }}</v-subheader>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-tabs v-model="tab" background-color="transparent"
                                        :right="$vuetify.breakpoint.mdAndUp"
                                        :grow="$vuetify.breakpoint.smAndDown"
                                        color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                    <v-tab ref="language" v-for="language in languages" :key="language">
                                        <span :class="exist_translations[language] ? '' : 'red--text '">
                                            {{ language }}
                                        </span>
                                    </v-tab>
                                </v-tabs>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <ValidationProvider ref="photo" rules="required"
                                                    v-slot="{ errors, valid }">
                                    <make-photo v-model="photo"/>
                                    <div v-if="!valid" class="mt-2" style="color: red">
                                        {{ errors[0] ? errors[0] : $t('photo_not_added') }}
                                    </div>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" sm="9" md="10">
                                <v-row>
                                    <v-col class="py-0" cols="12" sm="9">
                                        <ValidationProvider ref="attraction" rules="required|min:3|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="attraction" type="text" :error="!valid"
                                                          :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-nintendo-switch"
                                                          :label="$t('attraction_name')" color="primary" clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="3">
                                        <ValidationProvider ref="restriction" name="restriction"
                                                            rules="required|min_value:0|max_value:100"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="restriction" type="text" :error="!valid"
                                                          :error-messages="errors" :disabled="loading"
                                                          prepend-icon="mdi-face-man"
                                                          :label="$t('restriction')"
                                                          color="primary" clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="12">
                                        <ValidationProvider ref="park" name="park" rules="required"
                                                            v-slot="{ errors, valid }">
                                            <v-select v-model="park" :items="itemPark"
                                                      :error-messages="errors" :error="!valid"
                                                      :disabled="loadingPark" item-text="name"
                                                      item-value="id" return-object
                                                      prepend-icon="mdi-candycane" :label="$t('park')"
                                                      clearable></v-select>
                                        </ValidationProvider>
                                    </v-col>

                                </v-row>
                            </v-col>



                            <v-col cols="12" class="pt-0">
                                <v-subheader class="pt-0 mb-2 font-weight-medium">
                                    {{ $t('attraction_description') }}
                                </v-subheader>
                                <ValidationProvider ref="description"
                                                    rules="required|min:3|max:65535"
                                                    v-slot="{ errors, valid }">
                                    <tinymce-editor id="description" v-model="description"
                                                    :init="tinymceInit"></tinymce-editor>
                                    <div v-show="!valid" class="pt-0 mt-2" style="color: red">
                                        {{ errors[0] ? errors[0] : $t('description_is_not_filled_out') }}
                                    </div>
                                </ValidationProvider>
                            </v-col>


                            <v-col class="pt-0" cols="12">
                                <v-expansion-panels :disabled="loading">
                                    <v-expansion-panel>
                                        <v-expansion-panel-header class="primary white--text">
                                            {{ $t('photo') }}
                                            <template v-slot:actions>
                                                <v-icon color="white">$expand</v-icon>
                                            </template>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <media-gallery v-model="images"/>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="py-0 d-flex justify-start" cols="12" sm="6">
                                <v-switch v-model="deleted" :disabled="loading"
                                          :label="deleted ? $t('deleted') : $t('delete')"
                                          @change="deleted ? active = false : active"
                                          color="red" hide-details></v-switch>
                            </v-col>
                            <v-col class="py-0 d-flex justify-start justify-sm-end" cols="12" sm="6">
                                <v-switch v-model="active" :disabled="loading || deleted"
                                          :label="$t('published')"
                                          color="primary" hide-details></v-switch>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="pt-8">
                        <v-progress-linear v-model="progress" :active="loading"
                                           class="mx-2"></v-progress-linear>
                        <v-spacer></v-spacer>
                        <v-btn @click="saveAttraction()" :disabled="invalid || loading"
                               :loading="loading" color="primary">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex"
import MediaGallery from '../components/MediaGallery.vue'
import TinymceEditor from '@tinymce/tinymce-vue'
import MakePhoto from "../components/MakePhoto";

export default {
    name: 'AttractionForm',
    components: {
        ValidationProvider,
        ValidationObserver,
        MakePhoto,
        MediaGallery,
        TinymceEditor
    },
    inject: ['forceRerender'],
    data() {
        return {
            heading: null,
            progress: 0,
            loading: false,

            tab: 0,
            language: null,
            errors: {},
            id: null,
            photo: null,
            description: null,
            restriction: null,


            park: null,
            itemPark: [],
            loadingPark: false,




            active: false,
            deleted: false,
            attraction: null,
            images: [],
            exist_translations: {},
            all_translations: true,
            passwordHidden: true,
        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang']),
        languages() {
            return this.listLanguages
        },
        tinymceInit() {
            return this.tinymceInitDefault()
        }
    },

    async mounted() {
        this.language = this.languages[this.tab]
        this.loading = true
        await this.getParks()
        await this.checkCreate()


    },
    methods: {
        async checkCreate() {
            if (this.$route.name === "attraction.create") {
                this.heading = this.$t('attraction_creation')
                this.loading = false
            } else {
                this.heading = this.$t('attraction_editing')
                if (this.$route.params.id) {
                    await this.getAttraction()

                }
            }
        },
        async setLanguage(val) {
            this.language = this.languages[val]
            await this.getParks()
            await this.checkCreate()
        },

        async getParks() {
            this.loadingPark = true;
            let params = {}
            params.filter = "search";
            await this.$http
                .get(`admin/park/`, {params: params})
                .then(res => {
                    this.itemPark = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('messages.failed_to_get_list_parks'));
                    this.itemPark = []

                })
                .finally(end => {
                    this.loadingPark = false
                });
        },
        async getAttraction() {
            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/attraction/${this.$route.params.id}`, {params: params})
                .then(async (res) => {
                    this.attraction = res.body.data.name
                    this.photo = res.body.data.photo
                    this.images = res.body.data.images
                    this.description = res.body.data.description
                    this.park = res.body.data.park
                    this.restriction = res.body.data.restriction
                    this.active = res.body.data.active
                    this.deleted = res.body.data.deleted
                    this.exist_translations = res.body.data.exist_translations
                    this.all_translations = res.body.data.all_translations

                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_attraction'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async saveAttraction() {
            var _this = this
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.languages && this.languages[this.tab]) {
                formData.append('language', this.languages[this.tab])
            }
            if (this.attraction) {
                formData.append('attraction', this.attraction)
            }
            if (this.active) {
                formData.append('active', 1)
            }
            if (this.deleted) {
                formData.append('deleted', 1)
            }
            if (this.description) {
                formData.append('description', this.description)
            }
            if (this.restriction) {
                formData.append('restriction', this.restriction)
            }



            if (this.park) {
                if (this.park.id) {
                    formData.append('park', this.park.id)
                } else {
                    formData.append('park', this.park)
                }
            }
            if (this.photo) {
                if (this.photo.length > 250) {
                    var mimeType = this.getMimeType(this.photo)
                    var blob = this.dataURL64toBlob(this.photo)
                    if (mimeType && blob) {
                        formData.append('photo', blob, mimeType)
                    }
                } else {
                    formData.append('photo', this.photo)
                }
            }

            if (this.images && this.images.length > 0) {
                for (let i in this.images) {
                    if (this.images[i].length > 250) {
                        var mimeTypeImages = await this.getMimeType(this.images[i])
                        var blobImages = await this.dataURL64toBlob(this.images[i])
                        if (mimeTypeImages && blobImages) {
                            formData.append(`images[${i}]`, blobImages, mimeTypeImages)
                        }
                    } else {
                        formData.append(`images[${i}]`, this.images[i])
                    }
                }
            }

            if (this.$route.params.id) {
                // Save
                await this.$http
                    .put(`admin/attraction/${this.$route.params.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('attraction_has_been_updated'))
                        if (res && res.body && res.body.data && res.body.data.exist_translations) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('attraction_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                // Add
                await this.$http
                    .post('admin/attraction', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('attraction_has_been_added'))
                        if (res && res.body && res.body.data && res.body.data.id) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations
                            this.$router.push({
                                name: 'attraction.edit',
                                params: {
                                    id: res.body.data.id
                                }
                            })
                        } else {
                            this.$router.push({
                                name: 'attraction'
                            })
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('attraction_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        }
    }
}
</script>
